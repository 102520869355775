import { EditorSDK } from '@wix/platform-editor-sdk';

import { createBIService } from '../../utils/bi';
import { APP_TOKEN } from '../constants';

interface OpenModalPanelProps<T = any> {
  editorSDK: EditorSDK;
  eventPayload?: T;
}

export async function onManagePages({ eventPayload, editorSDK }: OpenModalPanelProps) {
  const biService = await createBIService({ editorSDK });
  biService.managePagesAddMemberPagesClick({ origin: 'editor' });
  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: './assets/managePages.html',
    width: 1098,
    height: 696,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export async function openGeneralSettings({ editorSDK, eventPayload }: OpenModalPanelProps) {
  await editorSDK.editor.openModalPanel(APP_TOKEN, {
    url: './assets/generalSettings.html',
    width: 876,
    height: 631,
    shouldHideHeader: true,
    initialData: eventPayload,
  });
}

export function openMemberPrivacySettingsBM(editorSDK: EditorSDK) {
  return editorSDK.editor.openDashboardPanel(APP_TOKEN, {
    url: '/members-area-settings/member-privacy',
    closeOtherPanels: false,
  });
}
